import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../components/Sidepanel';
import Navbar from '../components/Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchLabelDetails, createExpense, updateExpense, deleteItemMaster, additemtoContainer } from '../api/api';
import { v4 as uuidv4 } from 'uuid';

export default function LabelDetails() {
  const { id } = useParams(); // Get the container id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [container, setContainers] = useState([]);
  const [formData, setFormData] = useState({
    contain: '',
    amount: '',
    username: localStorage.getItem('username')
  });
  const [selectedLabel, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchLabelItems();
  }, []);

  const fetchLabelItems = async () => {
    try {
      const container = await fetchLabelDetails(id);
      console.log(container);
      setContainers(container);
    } catch (error) {
      console.error('Error fetching container:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createExpense(formData);
      fetchLabelItems();
      toast.success('Container added successfully.!');
    } catch (error) {
      toast.error('Error adding Container. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateExpense(selectedLabel.id, formData);
      fetchLabelItems();
      toast.success('Container updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating Container. Please try again.');
    }
  };

  const handleUpdate = (contain) => {
    setSelectedTask(contain);
    setFormData({
      contain: contain.contain,
      amount: contain.amount
   });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Item?");
    if (isConfirmed) {
      try {
        await deleteItemMaster(userId);
        fetchLabelItems();
        toast.success('Item deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Item. Please try again.');
      }
    }
  };

  const handleInputChange = (e, id, field) => {
    const updatedContainer = container.map(contain => {
      if (contain.id === id) {
        return { ...contain, [field]: e.target.value };
      }
      return contain;
    });
    setContainers(updatedContainer);
  };


  const submitaddedItemtoContainer = async (e) => {
    e.preventDefault();
    try {
        const firstContainer = container[0]; // Access the first object in the array

      // Construct the payload with the updated container details
      const payload = {
        // Add all the necessary fields from your form data
        code: uuidv4(),
        item: formData.item,
        masterqty: formData.masterqty,
        container: firstContainer.container,
        containerID: firstContainer.containerID,
        labelid: firstContainer.labelid ,
        status: 1,
        username: localStorage.getItem('username')
      };

      console.log(payload)
  
      // Send a POST request to the backend endpoint
      // const response = await fetch('http://localhost:8080/additemtoContainer', {
      // const response = await fetch('https://backend.aptech.co.ke/additemtoContainer', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(payload)
      // });

      const response = await additemtoContainer(payload);
  
      if (response.ok) {
        toast.success('Form data submitted successfully.');
        fetchLabelItems()
      } else {
        toast.error('Error submitting form data.');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      toast.error('Error submitting form data. Please try again.');
    }
  };
  
  // for search 
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter items based on search query
  const filteredItems = container.filter(item =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase())
  );

    return (
        <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>

            <input
                className='form-control w-25 float-end mx-1'
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearchChange}
            />

            <h6><u>Labels Details</u></h6>

            {/* <div className='mt-4'>
            <form onSubmit={submitaddedItemtoContainer}>
                <div className='d-flex'>
                    <div className='mx-1'>
                    <label>Item</label>
                    <input
                        required
                        className='form-control'
                        type="text"
                        name="item"
                        value={formData.item}
                        onChange={handleChange}
                    />
                    </div>
                    <div className='mx-1'>
                    <label>QTY</label>
                    <input
                        required
                        className='form-control'
                        type="number"
                        name="masterqty"
                        value={formData.masterqty}
                        onChange={handleChange}
                    />
                    </div>
                    <div>
                    <button className='btn btn-primary mt-4' type="submit">Submit</button>
                    </div>
                </div>
                </form>
            </div> */}

            <table className="table table-striped">
            <thead>
              <tr>
                <td><strong>#</strong></td>
                <th>Item</th>
                <th>QTY</th>
                <th>Container</th>
                <th>Label</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.filter(contain => contain.status === 1).map((contain, index) => (
                <tr key={contain.id}>
                  <td>{index + 1}</td>
                  <td>{contain.item}</td>
                  <td>{contain.masterqty}</td>
                  <td>{contain.container}</td>
                  <td>{contain.labelid}</td>
                  <td>
                    {/* <i className="fa-solid fa-pen-to-square fa-1x" onClick={() => handleUpdate(contain)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                    <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Container</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <Expenseform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(contain.id)} style={{ paddingLeft: '10px'}}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ToastContainer />
          </div>
          }
          
          
          
          
          
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
    )
}
