// api.js
import axios from 'axios';

// const loggedinuserID = localStorage.getItem('userid')
// const BASE_URL = process.env.REACT_APP_SERVER_API;
const BASE_URL = 'https://backend.aptech.co.ke';
// const BASE_URL = 'https://svbackend.insia.co.ke';

// login api 
export const login = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, userData);
    return response
  } catch (error) {
    throw new Error('Error adding user:', error);
  }
};

// users API ***********************************************
export const fetchAllUsers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllUsers`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching users:', error);
  }
};

export const createUser = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createUser`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding user:', error);
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateUser/${userId}`, userData);
    return response;
  } catch (error) {
    throw new Error('Error updating user:', error);
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteUser/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting user:', error);
  }
};

// customers API ***************************************************
export const fetchAllCustomers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/fetchingAllCustomers`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching customers:', error);
    }
};
  
  export const createCustomer = async (userData) => {
    try {
      const response = await axios.post(`${BASE_URL}/createCustomer`, userData);
      return response.data;
    } catch (error) {
      throw new Error('Error adding customer:', error);
    }
};

export const updateCustomer = async (userId, userData) => {
    try {
      const response = await axios.patch(`${BASE_URL}/updateCustomer/${userId}`, userData);
      return response;
    } catch (error) {
      throw new Error('Error updating customer:', error);
    }
};

export const deleteCustomer = async (userId) => {
    try {
      const response = await axios.delete(`${BASE_URL}/deleteCustomer/${userId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting customer:', error);
    }
};

// tasks API ************************************************
export const fetchAllTasks = async (userid) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllTasks/${userid}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching tasks:', error);
  }
};

export const createTask = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createTask`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding Task:', error);
  }
};

export const updateTask = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateTask/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating Task:', error);
  }
};

export const deleteTask = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteTask/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting task:', error);
  }
};

// notes API *******************************************************
export const fetchingAllNotes = async (userid) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllNotes/${userid}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching notes:', error);
  }
};

export const createNote = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createNote`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding note:', error);
  }
};

export const updateNote = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateNote/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating note:', error);
  }
};

export const deleteNote = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteNote/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting note:', error);
  }
};

// labels API 
export const fetchingAllLabels = async (userid) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllLabels`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching labels:', error);
  }
};

export const createLabel = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createLabel`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding label:', error);
  }
};

export const fetchLabelDetails = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchLabelDetails/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching container:', error);
  }
};

export const updateLabel = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateLabel/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating label:', error);
  }
};

export const deleteLabel = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteLabel/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting label:', error);
  }
};

// add items API ****************************************
export const fetchAllItems = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllItems`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching items:', error);
  }
};

export const createItem = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createItem`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding item:', error);
  }
};

export const updateItem = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateItem/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating item:', error);
  }
};

export const importItemsCSV = async (formData) => {
  try {
    // console.log(formData)
    const response = await axios.post(`${BASE_URL}/importItemsCSV`, formData);
    return response.data;
  } catch (error) {
    throw new Error('Error importing items from CSV:', error);
  }
};

export const deleteItem = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteItem/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting item:', error);
  }
};

// add items to mastersheet API ****************************************
export const fetchAllItemsMaster = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllItemsMaster`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching items from mastersheet:', error);
  }
};

export const createItemMaster = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createItemMaster`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding item from mastersheet:', error);
  }
};

export const updateItemMaster = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateItemMaster/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating item from mastersheet:', error);
  }
};

export const updateMaxnum = async (formData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateMaxnum`, formData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating maxnum from mastersheet:', error);
  }
};

export const pushtows = async (containerId, value) => {
  try {
    console.log(containerId, value)
    const response = await axios.patch(`${BASE_URL}/pushtows/${containerId}`, { value });
    return response.data;
  } catch (error) {
    throw new Error('Error updating maxnum from mastersheet:', error);
  }
};

export const deleteItemMaster = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteItemMaster/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting item from mastersheet:', error);
  }
};


// expenses api **********************************************
export const fetchingAllExpenses = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllExpenses`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching expenses:', error);
  }
};

export const createExpense = async (userData) => {
  try {
    // console.log(userData)
    const response = await axios.post(`${BASE_URL}/createExpense`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding expense:', error);
  }
};

export const updateExpense = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateExpense/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating expense:', error);
  }
};

export const deleteExpense = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteExpense/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting expense:', error);
  }
};

// seller api **********************************************
export const fetchingAllSellers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllSellers`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching sellers:', error);
  }
};

export const fetchingAllCommisions = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllCommisions`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching sellers:', error);
  }
};

export const createSeller = async (userData) => {
  try {
    // console.log(userData)
    const response = await axios.post(`${BASE_URL}/createSeller`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding seller:', error);
  }
};

export const fetchCommision = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchCommision/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error updating seller:', error);
  }
};

export const deleteCommision = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteCommision/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting commision:', error);
  }
};

export const updateSeller = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateSeller/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating seller:', error);
  }
};

export const deleteSeller = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteSeller/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting seller:', error);
  }
};

// container api **********************************************
export const fetchingAllContainers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllContainers`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching container:', error);
  }
};

export const fetchContainerDetails = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchContainerDetails/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching container:', error);
  }
};

export const createContainer = async (userData) => {
  try {
    // console.log(userData)
    const response = await axios.post(`${BASE_URL}/createContainer`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error addingcontainer:', error);
  }
};

export const additemtoContainer = async (payload) => {
  try {
    // console.log(payload)
    const response = await axios.post(`${BASE_URL}/additemtoContainer`, payload);
    return response;
  } catch (error) {
    throw new Error('Error adding item container:', error);
  }
};

export const receiveContainer = async (updatedContainer) => {
  try {
    // console.log(updatedContainer)
    const response = await axios.patch(`${BASE_URL}/receiveContainer`, updatedContainer);
    return response;
  } catch (error) {
    throw new Error('Error updating container:', error);
  }
};

export const updateContainer = async (userId, userData) => {
  try {
    // console.log(userId, userData)
    const response = await axios.patch(`${BASE_URL}/updateContainer/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating container:', error);
  }
};

export const deleteContainer = async (userId) => {
  try {
    // console.log(userId)
    const response = await axios.delete(`${BASE_URL}/deleteContainer/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting container:', error);
  }
};

// sales API
export const creatingSale = async (saleData) => {
  try {
    // console.log(saleData)
    const response = await axios.post(`${BASE_URL}/creatingSale`, saleData);
    return response.data;
  } catch (error) {
    throw new Error('Error creating sale:', error);
  }
};

export const fetchAllSales = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllSales`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching sales:', error);
  }
};

export const fetchsalesDetails = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchsalesDetails/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching sales details:', error);
  }
};

export const fetchAllSalesStatus = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllSalesStatus`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching sales:', error);
  }
};

export const updateSale = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateSale/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating sale:', error);
  }
};

export const deleteSale = async (saleId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteSale/${saleId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting sale:', error);
  }
};

export const editSaleItem = async (saleId, editedSaleData) => {
  try {
    // console.log(saleId, editedSaleData)
    const response = await axios.patch(`${BASE_URL}/editSaleItem/${saleId}`, editedSaleData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating sale:', error);
  }
};

export const deleteSaleitem = async (saleId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteSaleitem/${saleId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting sale:', error);
  }
};

export const approveSale = async (userId) => {
  try {
    const response = await axios.patch(`${BASE_URL}/approveSale/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting sale:', error);
  }
};

export const makeSale = async (formData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/makeSale`,formData);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting sale:', error);
  }
};

export const creditNoteItem = async (creditNoteData) => {
  try {
    // console.log(creditNoteData)
    const response = await axios.patch(`${BASE_URL}/creditNoteItem`,creditNoteData);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting sale:', error);
  }
};

// customer orders 
export const deleteOrder = async (saleId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteOrder/${saleId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting order:', error);
  }
};

export const creatingOrder = async (orderData) => {
  try {
    // console.log(orderData)
    const response = await axios.post(`${BASE_URL}/creatingOrder`, orderData);
    return response.data;
  } catch (error) {
    throw new Error('Error creating sale:', error);
  }
};