import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { fetchAllSales } from '../../api/api';

export default function Payments() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [paymentFilter, setPaymentFilter] = useState('all');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    filterItems();
  }, [items, paymentFilter, startDate, endDate]);

  const fetchItems = async () => {
    try {
      const items = await fetchAllSales();
      console.log(items);
      setItems(items);
      setFilteredItems(items);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const filterItems = () => {
    let filtered = items;

    if (paymentFilter !== 'all') {
      filtered = filtered.filter(item => item.payment === paymentFilter);
    }

    if (startDate) {
      filtered = filtered.filter(item => new Date(item.createdAt) >= new Date(startDate));
    }

    if (endDate) {
      filtered = filtered.filter(item => new Date(item.createdAt) <= new Date(endDate));
    }

    setFilteredItems(filtered);
  };

  const handlePaymentFilterChange = (event) => {
    setPaymentFilter(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const calculateSubtotal = () => {
    return filteredItems.reduce((total, item) => total + item.saletotal, 0);
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />

        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && (
            <div className='mt-4'>
              <div className='d-flex justify-content-between'>
                <h5><u>Payment Report</u></h5>
                <div>
                  <input
                    type='date'
                    className='form-control d-inline-block w-auto me-2'
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                  <input
                    type='date'
                    className='form-control d-inline-block w-auto me-2'
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                  <select className='form-select d-inline-block w-auto' value={paymentFilter} onChange={handlePaymentFilterChange}>
                    <option value='all'>All</option>
                    <option value='bank'>Bank</option>
                    <option value='mpesa'>Mpesa</option>
                    <option value='cash'>Cash</option>
                  </select>
                </div>
              </div>

              <div className='row text-center mt-4'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <td><strong>#</strong></td>
                      <th>Payment</th>
                      <th>Invoice</th>
                      <th>Seller</th>
                      <th>Customer</th>
                      <th>Price</th>
                      <th>QTY</th>
                      <th>Total</th>
                      <th>Item</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.payment}</td>
                        <td>{item.invoicenumber}</td>
                        <td>{item.seller}</td>
                        <td>{item.customer}</td>
                        <td>{item.saleprice}</td>
                        <td>{item.saleqty}</td>
                        <td>{item.saletotal}</td>
                        <td>{item.itemid.item}</td>
                        <td>{item.createdAt}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className='text-success' colSpan="7"><strong>Subtotal</strong></td>
                      <td className='text-success'><strong>{calculateSubtotal()}</strong></td>
                      <td colSpan="3"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* the side panel */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
