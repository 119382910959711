import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../components/Sidepanel';
import Navbar from '../components/Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchsalesDetails, fetchingAllSellers, creditNoteItem } from '../api/api';

export default function SolditemsDetails() {
  const { id } = useParams(); // Get the sales id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [sellers, setSellers] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [etrNumber, setEtrNumber] = useState('');
  const [commission, setCommission] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [paymentmethod, setPaymentmethod] = useState('')

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    sale: '',
    amount: '',
    username: localStorage.getItem('username')
  });
  const [selectedLabel, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchLabelItems();
    fetchSellers()
  }, []);

  const fetchLabelItems = async () => {
    try {
      const sales = await fetchsalesDetails(id);
      console.log('sales',sales);
      setSales(sales);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchSellers = async () => {
    try {
      const sellers = await fetchingAllSellers();
      console.log(sellers);
      setSellers(sellers);
    } catch (error) {
      console.error('Error fetching sellers:', error);
    }
  };

  const totalAmount = sales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
  
  // for search 
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter items based on search query
  const filteredItems = sales.filter(item =>
    item.customer.toLowerCase().includes(searchQuery.toLowerCase())
  );

//   for doing the credit note 
  const handleCNQtyChange = (e, index) => {
    const updatedSales = [...sales];
    updatedSales[index].cnqty = parseInt(e.target.value, 10); // Convert to integer
    updatedSales[index].saletotal = updatedSales[index].cnqty * updatedSales[index].saleprice; // Recalculate total
    setSales(updatedSales);
  };
  
  const handleSubmit = async (sale) => {
    try {
      // Display confirmation prompt
      const isConfirmed = window.confirm('Are you sure you want to submit the credit note item?');
  
      // Check if user confirmed
      if (isConfirmed) {
        // Prepare data to send to the creditnoteitem API
        const creditNoteData = {
          // Include all necessary details from the sale object
          itemId: sale.itemid.id,
          saleQty: sale.saleqty,
          saleId: sale.id,
          cnqty: sale.cnqty,
          salePrice: sale.saleprice,
          saletotal: sale.saletotal,
          saleType: sale.saletype,
          saleStatus: sale.salestatus,
          customer: sale.customer,
          // Include other necessary data (invoiceNumber, etc.) if needed
        };
  
        // Call the creditNoteItem API with the prepared data
        const response = await creditNoteItem(creditNoteData);
  
        // Handle success response from the API
        console.log('Credit note item submitted successfully:', response);
        // Optionally, show a success message to the user
        toast.success('Credit note item submitted successfully');
  
        // You can also update the UI or perform any other necessary actions after successful submission
      }
    } catch (error) {
      // Handle error response from the API
      console.error('Error submitting credit note item:', error);
      // Optionally, show an error message to the user
      toast.error('Error submitting credit note item');
    }
  };

    return (
        <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>
            <h5><u>Sold Items Details</u></h5>

            <table className="table table-striped">
            <thead>
              <tr>
                <td><strong>#</strong></td>
                <th>Item</th>
                <th>QTY</th>
                <th className='text-danger'>CN.QTY</th>
                <th>Price</th>
                <th>Total</th>
                <th>Sale Type</th>
                <th>Status</th>
                <th>Customer</th>
                <th></th>                
              </tr>
            </thead>
            <tbody>
              {filteredItems.filter(sale => sale).map((sale, index) => (
                <tr key={sale.id}>
                  <td>{index + 1}</td>
                  <td>{sale.itemid.item}</td>
                  <td>{sale.saleqty}</td>
                  <td className='text-danger'>
                    <input
                        className='form-control'
                        type="number"
                        value={sale.cnqty}
                        onChange={(e) => handleCNQtyChange(e, index)}
                        max={sale.saleqty}
                        min='0'
                    />
                  </td>                  <td>{sale.saleprice}</td>
                  <td>{sale.saletotal}</td>
                  <td>{sale.saletype}</td>
                  <td>{sale.salestatus === 0 ?<p className='text-danger fw-bold'>Not sold</p> : <p className='text-success fw-bold'>Sold</p>}</td>
                  <td>{sale.customer}</td>
                  <td>
                    {/* <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(sale.id)} style={{ paddingLeft: '10px'}}></i> */}
                    <i className="fa-solid fa-check fa-1x" onClick={() => handleSubmit(sale)} style={{ paddingLeft: '10px', color: 'green'}}></i>
                  </td>
                </tr>
              ))}
            </tbody>            
          </table>

          <h5 className='text-success'>Sub Total: {totalAmount}</h5>

          <ToastContainer />
          </div>
          }
          
          
          
          
          
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
    )
}
