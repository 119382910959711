import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllItemsMaster, creatingSale } from '../../api/api';

export default function Sales() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const items = await fetchAllItemsMaster();
      console.log(items)
      setItems(items);
      // Initialize formData with default values for sale quantity
      const initialFormData = {};
      items.forEach(item => {
        initialFormData[`sellqty_${item.id}`] = 0;
        initialFormData[`sellprice_${item.id}`] = item.saleprice || 0;
        initialFormData[`total_${item.id}`] = 0;
      });
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, itemId] = name.split('_');
    const updatedFormData = { ...formData, [name]: value };
  
    // Calculate total for the item if both sale quantity and sale price are provided
    if (field === 'sellqty' && formData[`sellprice_${itemId}`]) {
      const sellQty = parseFloat(value);
      const sellPrice = parseFloat(formData[`sellprice_${itemId}`]);
      updatedFormData[`total_${itemId}`] = (sellQty * sellPrice).toFixed(2);
    } else if (field === 'sellprice' && formData[`sellqty_${itemId}`]) {
      const sellQty = parseFloat(formData[`sellqty_${itemId}`]);
      const sellPrice = parseFloat(value);
      updatedFormData[`total_${itemId}`] = (sellQty * sellPrice).toFixed(2);
    }
  
    setFormData(updatedFormData);
  };

  // Calculate subtotal of all totals
  const subtotal = items.reduce((acc, item) => {
    const total = parseFloat(formData[`total_${item.id}`]) || 0;
    return acc + total;
  }, 0);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare itemsData object with updated sell prices, sale quantities, and labels
      const itemsData = [];
      console.log('FormData:', formData);
      console.log('Items:', items);
  
      for (const key in formData) {
        const [field, itemId] = key.split('_');
        if (field === 'sellprice') {
          // Extract the sale price and sale quantity
          const sellPrice = formData[key];
          const sellQty = formData[`sellqty_${itemId}`];
  
          // Ensure itemId is a string or number, depending on the type in items array
          const item = items.find(item => item.id.toString() === itemId.toString());
  
          if (item) {
            const label = item.label; // Find the corresponding label
            console.log('Item found:', item);
  
            // Add item data to the itemsData array
            itemsData.push({ id: itemId, sellprice: sellPrice, sellqty: sellQty, label: label });
          } else {
            console.warn(`Item with id ${itemId} not found. Available IDs: ${items.map(item => item.id).join(', ')}`);
          }
        }
      }
  
      // Check if itemsData array is empty
      if (itemsData.length === 0) {
        // Show toast message if itemsData is empty
        toast.error('Please add items to the sale with quantities and prices');
        return; // Stop further execution
      }
  
      // Additional data from the form
      const saleType = e.target.elements.saletype.value;
      const customer = e.target.elements.customer.value;
      const saleid = uuidv4();
      const seller = localStorage.getItem('username');
  
      // Create the sale object
      const saleData = {
        saleType,
        customer,
        saleid,
        seller,
        items: itemsData
      };
  
      // Call the API function to handle the sale and show toast message
      const response = await creatingSale(saleData);
  
      console.log(response.message);
  
      if (response.message === 'sold') {
        toast.success('Sale created successfully');
        // Wait for 2 seconds before reloading the page
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setFormData({});
      } else {
        toast.error('Error creating sale. Please try again.');
      }
  
    } catch (error) {
      console.error('Error creating sale:', error);
      // Show error message
      toast.error('Error creating sale. Please try again.');
    }
  };  


  // Function to handle container selection
  const handleLabelSelect = (label) => {
    setSelectedLabel(label);
  };
  // for search 
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const uniqueLabels = [...new Set(items.map(item => item.label))];

  // Filter items based on search query and selected label
  const filteredItems = items.filter(item =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (!selectedLabel || item.label === selectedLabel)
  );

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
            <div>
              <div className='mt-5'>

              <div className='mt-3'>
              <div className='float-end d-flex'>
              <Link to='/wholesale'>
                <button className='btn btn-warning btn-sm'>Whole Sale</button>
              </Link>

              <select
                className='form-control mx-1'
                value={selectedLabel}
                onChange={(e) => handleLabelSelect(e.target.value)}
              >
                <option value="">All Labels</option>
                {uniqueLabels.map((label, index) => (
                  <option key={index} value={label}>{label}</option>
                ))}
              </select>

                <input
                  className='form-control w-50'
                  type="text"
                  placeholder="Search items..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>

              <h5><u>Retail Sales page</u></h5>
              </div>

              <form onSubmit={handleSubmit}>

              <div className='d-flex mt-5'>
                <select class="form-select w-25" name='saletype' required aria-label="Default select example">
                  <option selected value="retail">Retail</option>
                </select>

                {/* <select class="form-select w-25 mx-2" name='paymentmethod' required aria-label="Default select example">
                  <option disabled selected value="">Payment Method</option>
                  <option value="bank">Bank</option>
                  <option value="mpesa">Mpesa</option>
                  <option value="cash">Cash</option>
                </select> */}

                {/* <input type="text" name='invoicenumber' required class="form-control w-25 mx-2" id="invoicenumber" placeholder='Enter invoice number' />

                <input type="text" name='etrnumber' required class="form-control w-25 mx-2" id="etrnumber" placeholder='Enter ETR number' /> */}

                <input type="text" name='customer' required class="form-control w-25 mx-2" id="customer" placeholder='Enter customer name' />

                <button className='btn btn-sm btn-success'>Sell</button>
              </div>

              <table className='w-100 mt-3 text-light'>
              <thead style={{background: 'green'}}>
                  <tr>
                    <td><strong>#</strong></td>
                    <th className='text-success text-light py-2'>Sub Total</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className='text-success text-light'>{subtotal.toFixed(2)}</th>
                  </tr>
                </thead>
              </table>

              <div className='mt-4' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td><strong>#</strong></td>
                    <th>Item</th>
                    <th>QTY</th>
                    <th>Container</th>
                    <th>Label</th>
                    <th>Sale QTY</th>
                    <th>Sale Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.item}</td>
                      <td>{item.masterqty}</td>
                      <td>{item.container}</td>
                      <td>{item.label}</td>
                      <td><input type="number" min='0' className='form-control' name={`sellqty_${item.id}`} value={formData[`sellqty_${item.id}`]} onChange={handleChange} /></td>
                      <td><input type="number" min='0' className='form-control' name={`sellprice_${item.id}`} value={formData[`sellprice_${item.id}`]} onChange={handleChange} /></td>
                      <td>{formData[`total_${item.id}`] || 0}</td>
                    </tr>
                  ))}
                </tbody>

              </table>
              </div>

              </form>
              <ToastContainer />
            </div>

            </div>
          }
          
          
          
          {/* other code starts here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
