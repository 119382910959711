import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllItemsMaster, creatingSale, fetchAllCustomers } from '../../api/api';

export default function Wholesale() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [items, setItems] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  
  const [selectedLabel, setSelectedLabel] = useState('');

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      // Fetch all items
      const allItems = await fetchAllItemsMaster();
      const customers = await fetchAllCustomers();
      setCustomers(customers);
      console.log(customers)
  
      // Filter items with pushtows equal to 1
      const filteredItems = allItems.filter(item => item.pushtows === 1);
      
      // Set the filtered items in state
      setItems(filteredItems);
      console.log(filteredItems)
  
      // Initialize formData with default values for sale quantity
      const initialFormData = {};
      filteredItems.forEach(item => {
        initialFormData[`sellqty_${item.id}`] = 0;
        initialFormData[`sellprice_${item.id}`] = item.saleprice || 0;
        initialFormData[`total_${item.id}`] = 0;
      });
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };
  

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [sale, customer, itemId] = name.split('_');

    const updatedSaleQty = parseFloat(value);
    const updatedSale = { ...sale, saleqty: updatedSaleQty };
  
    // Find the index of the customer in the customers array
    const customerIndex = customers.findIndex(cus => cus.id === customer.id);
  
    // Create a new array with the updated sale for the corresponding customer
    const updatedCustomers = [...customers];
    updatedCustomers[customerIndex] = {
      ...updatedCustomers[customerIndex],
      Sales: updatedCustomers[customerIndex].Sales.map(s => s.item === itemId ? updatedSale : s)
    };
  
    // Set the state with the new array
    setCustomers(updatedCustomers);
  
    // Update the formData with the new sale quantity
    const updatedFormData = { ...formData, [`sellqty_${itemId}`]: updatedSaleQty };
    setFormData(updatedFormData);
    
    // Recalculate and update total, orders, and balance
    const updatedItems = items.map(item => {
      if (item.id === itemId) {
        const totalOrders = updatedCustomers.reduce((total, cus) => {
          const sale = cus.Sales.find(sale => sale.item === itemId);
          return total + (sale ? parseFloat(sale.saleqty) : 0);
        }, 0);
        const balance = item.masterqty - totalOrders;
        return { ...item, totalOrders, balance };
      }
      return item;
    });
    setItems(updatedItems);
  };
      

  // Calculate subtotal of all totals
  const subtotal = items.reduce((acc, item) => {
    const total = parseFloat(formData[`total_${item.id}`]) || 0;
    return acc + total;
  }, 0);
    
  // Calculate total for each customer
  const customerTotals = customers.map(customer => {
    return customer.Sales.reduce((total, sale) => {
      const item = items.find(item => item.id == sale.item);
      console.log('totals', item)
      if (item) {
        return total + (parseFloat(sale.saleqty) * parseFloat(item.saleprice) || 0);
      }
      return total;
    }, 0);
  });

  // Calculate total orders for each item
const calculateTotalOrders = (itemId) => {
  return customers.reduce((total, cus) => {
    const sale = cus.Sales.find(sale => sale.item == itemId);
    return total + (sale ? parseFloat(sale.saleqty) : 0);
  }, 0);
};

const handleSubmit = async (e, customer) => {
  e.preventDefault();
  try {
    // Prepare itemsData object with updated sell prices, sale quantities, and labels
    const itemsData = [];

    customer.Sales.forEach(sale => {
      const item = items.find(item => item.id === sale.item);
      if (item && parseFloat(sale.saleqty) > 0) {
        itemsData.push({
          id: sale.item,
          sellprice: item.saleprice,
          sellqty: sale.saleqty,
          label: item.label
        });
      }
    });

    // Additional data from the form
    const saleType = 'wholesale';
    const saleid = uuidv4();
    const seller = localStorage.getItem('username');

    // Create the sale object
    const saleData = {
      saleType,
      customer,
      saleid,
      seller,
      items: itemsData
    };

    // Call the API function to handle the sale and show toast message
    const response = await creatingSale(saleData);

    console.log(response.message);

    if (response.message === 'sold') {
      toast.success('Sale created successfully');
      // Wait for 2 seconds before reloading the page
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      setFormData({});
    } else {
      toast.error('Error creating sale. Please try again.');
    }

  } catch (error) {
    console.error('Error creating sale:', error);
    // Show error message
    toast.error('Error creating sale. Please try again.');
  }
};


  // Function to handle container selection
  const handleLabelSelect = (label) => {
    setSelectedLabel(label);
  };
  // for search 
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const uniqueLabels = [...new Set(items.map(item => item.label))];

  // Filter items based on search query and selected label
  const filteredItems = items.filter(item =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (!selectedLabel || item.label === selectedLabel)
  );

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
            <div>
              <div className='mt-5'>

              <div className='mt-3'>
              <div className='float-end d-flex'>
              <Link to='/sales'>
                <button className='btn btn-danger'>Retail</button>
              </Link>

              <select
                className='form-control mx-1'
                value={selectedLabel}
                onChange={(e) => handleLabelSelect(e.target.value)}
              >
                <option value="">All Labels</option>
                {uniqueLabels.map((label, index) => (
                  <option key={index} value={label}>{label}</option>
                ))}
              </select>

                <input
                  className='form-control w-50'
                  type="text"
                  placeholder="Search items..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>

              <h5><u>Whole Sale page</u></h5>
              </div>

              <form onSubmit={handleSubmit}>

              <table className='w-100 mt-3 text-light'>

              </table>

              <div className='mt-4' style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'auto' }}>
              <table className="table table-striped" style={{ minWidth: '100%' }}>
                <thead>
                  <tr>
                    {/* <td><strong>#</strong></td> */}
                    <th>Item</th>
                    <th>QTY</th>
                    <th>Orders</th>
                    <th>Balance</th>
                    <th>Container</th>
                    <th>Label</th>
                    <th>Price</th>
                    {customers.map((cus) => (
                        <th className=''>{cus.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredItems.map((item, index) => (
                    <tr key={item.id}>
                      {/* <td>{index + 1}</td> */}
                      <td>{item.item}</td>
                      <td>{item.masterqty}</td>
                      <td>{calculateTotalOrders(item.id)}</td>
                      <td>{item.masterqty - calculateTotalOrders(item.id)}</td> {/* Calculate balance */}
                      <td>{item.container}</td>
                      <td>{item.label}</td>
                      <td><input type="number" min='0' className='form-control' name={`sellprice_${item.id}`} value={formData[`sellprice_${item.id}`]} onChange={handleChange} /></td>
                      {customers.map((cus) => {
                        const sale = cus.Sales.find(sale => sale.item == item.id);
                        return (
                          <td key={cus.id + '-' + item.id}>
                            <input 
                              type="number" 
                              min='0'  
                              name={`sellqty_${item.id}`}
                              className='form-control' 
                              onChange={(e) => handleChange(e, sale, cus, item.id)}
                              value={sale ? sale.saleqty : 0}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
                <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    {customers.map((cus, index) => (
                      <th key={cus.id}>{customerTotals[index]}</th>
                    ))}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    {customers.map((cus) => (
                      <th key={cus.id}>
                        <button className='btn btn-success btn-sm' onClick={(e) => handleSubmit(e, cus)}>Submit</button>
                      </th>
                    ))}
                  </tr>
                </thead>

              </table>
              </div>

              </form>
              <ToastContainer />
            </div>

            </div>
          }
          
          
          {/* other code starts here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
