// Users.js
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserForm from '../../components/Userform';
import { Link } from 'react-router-dom';
import { fetchAllUsers, createUser, updateUser, deleteUser } from '../../api/api';

export default function Users() {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    username: '',
    department: '',
    designation: '',
    email: '',
    phone: '',
    password: ''
  });
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const users = await fetchAllUsers();
      setUsers(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUser(formData);
      fetchUsers();
      toast.success('User added successfully.!');
    } catch (error) {
      toast.error('Error adding user. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser(selectedUser.id, formData);
      fetchUsers();
      toast.success('User updated successfully.');
      setSelectedUser(null);
    } catch (error) {
      toast.error('Error updating user. Please try again.');
    }
  };

  const handleUpdate = (user) => {
    setSelectedUser(user);
    setFormData({
      username: user.username,
      department: user.department,
      designation: user.designation,
      email: user.email,
      phone: user.phone,
      password: ''
    });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this user?");
    if (isConfirmed) {
      try {
        await deleteUser(userId);
        fetchUsers();
        toast.success('User deleted successfully.');
      } catch (error) {
        toast.error('Error deleting user. Please try again.');
      }
    }
  };

  return (
    <>
      <div className='container mt-5'>
        <Link to={`/sellers`}><button className='btn btn-primary btn-sm float-end'>Sellers</button></Link>
        <button className='btn btn-success btn-sm float-end mx-1' data-bs-toggle="modal" data-bs-target="#exampleModal">Create User</button>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title fs-5" id="exampleModalLabel">Add User</h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <UserForm formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>

        <h5><u>Users</u></h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <td><strong>#</strong></td>
              <th>Username</th>
              <th>Department</th>
              <th>Designation</th>
              <th>Email</th>
              <th>Phone</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.username}</td>
                <td>{user.department}</td>
                <td>{user.designation}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>
                  <i className="fa-solid fa-pen-to-square fa-1x" onClick={() => handleUpdate(user)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                  <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h6 className="modal-title fs-5" id="exampleModalLabel2">Update User</h6>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <UserForm formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(user.id)} style={{ paddingLeft: '10px'}}></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </>
  );
}
