import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { fetchingAllLabels, createLabel, updateLabel, deleteLabel } from '../../api/api';
import Labelform from '../../components/Labelform';

export default function Labels() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const userId = localStorage.getItem('userid');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const [labels, setLabels] = useState([]);
  const [formData, setFormData] = useState({
    labelname: '',
    userid: userId,
  });
  const [selectedLabel, setselectedLabel] = useState(null);

  useEffect(() => {
    fetchLabels();
  }, []);

  const fetchLabels = async () => {
    try {
      const labels = await fetchingAllLabels();
      // console.log('labels', labels)
      setLabels(labels);
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createLabel(formData);
      fetchLabels();
      toast.success('Label added successfully.!');
    } catch (error) {
      toast.error('Error adding label. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateLabel(selectedLabel.id, formData);
      fetchLabels();
      toast.success('Label updated successfully.');
      setselectedLabel(null);
    } catch (error) {
      toast.error('Error updating Label. Please try again.');
    }
  };

  const handleUpdate = (label) => {
    setselectedLabel(label);
    setFormData({
      label: label.labelname,
    });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this label?");
    if (isConfirmed) {
      try {
        await deleteLabel(userId);
        fetchLabels();
        toast.success('Label deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Label. Please try again.');
      }
    }
  };

  // for search 
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter labels based on search query
  const filteredItems = labels.filter(label =>
    label.labelname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-5'>
          
          <div>
            <input
              className='form-control w-25 float-end'
              type="text"
              placeholder="Search labels..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>


          <button className='btn btn-success float-end mx-1' data-bs-toggle="modal" data-bs-target="#exampleModal1">Create Label</button>

          <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title fs-5" id="exampleModalLabel1">Add Label</h6>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <Labelform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                </div>
              </div>
            </div>
          </div>

          <h5><u>Labels</u></h5>

          <table className="table table-striped mt-2">
            <thead>
              <tr>
                <td><strong>#</strong></td>
                <th>Label</th>
                <th>Created</th>
                <th>Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>

            {filteredItems.length < 1 ? (
              <div>
                <h5>There are Zero labels</h5>
              </div>
            ) : (filteredItems.filter(label => label).map((label, index) => (
                <tr key={label.id}>
                  <td>{index + 1}</td>
                  <td>{label.labelname}</td>
                  <td>{label.createdAt}</td>
                  <td>{label.updatedAt}</td>
                  <td>
                  {/* <i className="fa-solid fa-pen-to-square fa-1x" onClick={() => handleUpdate(label)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                  <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Label</h6>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Labelform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit}  />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(label.id)} style={{ paddingLeft: '10px'}}></i>
                  <Link to={`/labeldetails/${label.id}`}><i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px'}}></i></Link>

                  </td>
                </tr>
              )))}
            </tbody>
          </table>
          <ToastContainer />
        </div>
          }
          
          
          
          {/* others begin here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
