import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../components/Sidepanel';
import Navbar from '../components/Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchsalesDetails, fetchingAllSellers, createExpense, updateExpense, deleteSale, additemtoContainer, makeSale } from '../api/api';
import { v4 as uuidv4 } from 'uuid';

export default function InvoiceDetails() {
  const { id } = useParams(); // Get the sales id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [sellers, setSellers] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [etrNumber, setEtrNumber] = useState('');
  const [commission, setCommission] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [paymentmethod, setPaymentmethod] = useState('')

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    sale: '',
    amount: '',
    username: localStorage.getItem('username')
  });
  const [selectedLabel, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchLabelItems();
    fetchSellers()
  }, []);

  const fetchLabelItems = async () => {
    try {
      const sales = await fetchsalesDetails(id);
      console.log('sales',sales);
      setSales(sales);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchSellers = async () => {
    try {
      const sellers = await fetchingAllSellers();
      console.log(sellers);
      setSellers(sellers);
    } catch (error) {
      console.error('Error fetching sellers:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createExpense(formData);
      fetchLabelItems();
      toast.success('Container added successfully.!');
    } catch (error) {
      toast.error('Error adding Container. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateExpense(selectedLabel.id, formData);
      fetchLabelItems();
      toast.success('Container updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating Container. Please try again.');
    }
  };

  const handleUpdate = (sale) => {
    setSelectedTask(sale);
    setFormData({
      sale: sale.sale,
      amount: sale.amount
   });
  };

  const handleDelete = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Item?");
    if (isConfirmed) {
      try {
        await deleteSale(saleId);
        fetchLabelItems();
        toast.success('Item deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Item. Please try again.');
      }
    }
  };

  const handleInputChange = (e, id, field) => {
    const updatedContainer = sales.map(sale => {
      if (sale.id === id) {
        return { ...sale, [field]: e.target.value };
      }
      return sale;
    });
    setSales(updatedContainer);
  };

  const handleSell = async (invoiceNumber, etrNumber, commission, sellerId, paymentmethod) => {
    try {
      const formData = {
        // Include invoice number, ETR number, commission, and seller ID in the request payload
        invoiceNumber: invoiceNumber,
        etrNumber: etrNumber,
        commission: commission,
        paymentmethod: paymentmethod,
        sellerId: sellerId,
        saleId: id
      };
  
      // Call the makeSale API with the updated formData
      await makeSale(formData);
      navigate('/invoices')
      // fetchLabelItems();
      // fetchSellers();
      // toast.success('Sold successfully.');
    } catch (error) {
      toast.error('Error when selling. Please try again.');
    }
  };

  // Update state variables when input fields change
  const handleInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const handleEtrNumberChange = (e) => {
    setEtrNumber(e.target.value);
  };

  const handleCommissionChange = (e) => {
    setCommission(e.target.value);
  };

  const handleSellerIdChange = (e) => {
    setSellerId(e.target.value);
  };

  const handlepaymentmethodChange = (e) => {
    setPaymentmethod(e.target.value);
  };
  
  // Call handleSell with the required parameters when selling
  const handleSellClick = (invoiceNumber, etrNumber, commission, sellerId, paymentmethod) => {
    handleSell(invoiceNumber, etrNumber, commission, sellerId, paymentmethod);
  };


  const submitaddedItemtoContainer = async (e) => {
    e.preventDefault();
    try {
        const firstContainer = sales[0]; // Access the first object in the array

      // Construct the payload with the updated sales details
      const payload = {
        // Add all the necessary fields from your form data
        code: uuidv4(),
        item: formData.item,
        masterqty: formData.masterqty,
        sales: firstContainer.sales,
        containerID: firstContainer.containerID,
        labelid: firstContainer.labelid ,
        status: 1,
        username: localStorage.getItem('username')
      };

      console.log(payload)
  
      // Send a POST request to the backend endpoint
    //   const response = await fetch('http://localhost:8080/additemtoContainer', {
      // const response = await fetch('https://backend.aptech.co.ke/additemtoContainer', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(payload)
      // });
  
      const response = await additemtoContainer(payload);

      if (response.ok) {
        toast.success('Form data submitted successfully.');
        fetchLabelItems()
      } else {
        toast.error('Error submitting form data.');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      toast.error('Error submitting form data. Please try again.');
    }
  };

  const totalAmount = sales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
  
  // for search 
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter items based on search query
  const filteredItems = sales.filter(item =>
    item.customer.toLowerCase().includes(searchQuery.toLowerCase())
  );


    return (
        <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>

            {/* <input
                className='form-control w-25 float-end mx-1'
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearchChange}
            /> */}
            <h6><u>Sale Details</u></h6>

            {/* <div className='mt-4'>
            <form onSubmit={submitaddedItemtoContainer}>
                <div className='d-flex'>
                    <div className='mx-1'>
                    <label>Item</label>
                    <input
                        required
                        className='form-control'
                        type="text"
                        name="item"
                        value={formData.item}
                        onChange={handleChange}
                    />
                    </div>
                    <div className='mx-1'>
                    <label>QTY</label>
                    <input
                        required
                        className='form-control'
                        type="number"
                        name="masterqty"
                        value={formData.masterqty}
                        onChange={handleChange}
                    />
                    </div>
                    <div>
                    <button className='btn btn-primary mt-4' type="submit">Submit</button>
                    </div>
                </div>
                </form>
            </div> */}

            <table className="table table-striped">
            <thead>
              <tr>
                <td><strong>#</strong></td>
                <th>Item</th>
                <th>QTY</th>
                <th>Price</th>
                <th>Total</th>
                <th>Sale Type</th>
                <th>Status</th>
                <th>Customer</th>
                <th></th>                
              </tr>
            </thead>
            <tbody>
              {filteredItems.filter(sale => sale).map((sale, index) => (
                <tr key={sale.id}>
                  <td>{index + 1}</td>
                  <td>{sale.itemid.item}</td>
                  <td>{sale.saleqty}</td>
                  <td>{sale.saleprice}</td>
                  <td>{sale.saletotal}</td>
                  <td>{sale.saletype}</td>
                  <td>{sale.salestatus === 0 ?<p className='text-danger fw-bold'>Not sold</p> : <p className='text-success fw-bold'>Sold</p>}</td>
                  <td>{sale.customer}</td>
                  <td>
                    {/* <i className="fa-solid fa-pen-to-square fa-1x" onClick={() => handleUpdate(sale)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                    <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Container</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <Expenseform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(sale.id)} style={{ paddingLeft: '10px'}}></i>
                  </td>
                </tr>
              ))}
            </tbody>

            <thead>
                <tr>
                <td><strong>#</strong></td>
                
                <th>
                  <input type='number' className='form-control' placeholder='Commission' onChange={handleCommissionChange} />
                </th>
                <th>
                  <select className="form-select" aria-label="Default select example" value={sellerId} onChange={handleSellerIdChange}>
                    <option disabled selected value="">Seller</option>
                    {sellers && sellers.map(seller => (
                      <option key={seller.id} value={seller.id}>{seller.sellername}</option>
                    ))}
                  </select>
                </th>
                <th>
                  <select class="form-select" name='paymentmethod' required aria-label="Default select example" onChange={handlepaymentmethodChange}>
                    <option disabled selected value="">Payment Method</option>
                    <option value="bank">Bank</option>
                    <option value="mpesa">Mpesa</option>
                    <option value="cash">Cash</option>
                  </select>
                </th>
                <th>
                  <input type="text" name='invoicenumber' required class="form-control" id="invoicenumber" placeholder='Enter invoice number' onChange={handleInvoiceNumberChange} />
                </th>
                <th>
                  <input type="text" name='etrnumber' required class="form-control" id="etrnumber" placeholder='Enter ETR number' onChange={handleEtrNumberChange} />
                </th>
                <th>
                  <button onClick={() => handleSellClick(invoiceNumber, etrNumber, commission, sellerId, paymentmethod)} className='btn btn-success'>Sell</button>
                </th>
                </tr>
            </thead>
            
          </table>

          <h5 className='text-success'>Sub Total: {totalAmount}</h5>

          <ToastContainer />
          </div>
          }
          
          
          
          
          
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
    )
}
