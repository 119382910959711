import React, {useEffect, useState} from 'react';
import { useUser } from '../contexts/UserContext';

const SidePanel = ({ isOpen, toggleSidePanel, handleMenuItemClick }) => {
  const { user } = useUser();
  const designation = localStorage.getItem('designation'); // Get designation from local storage
  // console.log("User context value:", user);
  const [displayUsername, setDisplayUsername] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayUsername(false);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  }, []);
  
  return (
    <>
      <div>
        <div className={`side-panel ${isOpen ? 'open' : ''}`}>
          <div className="panel-content">
            {displayUsername && user && (
              <div>

              </div>
            )}
            {isOpen ? (
              <>
              <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('profile')}>
                <i className="sidepicons fa-solid fa-address-card mt-3"></i>
                <span className='mt-3' style={{ marginLeft: '14px' }}>Profile</span>
              </h6>
              <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('orders')}>
                <i className="sidepicons fa-solid fa-layer-group mt-3"></i>
                <span className='mt-3' style={{ marginLeft: '14px' }}>Orders</span>
              </h6>

              {designation !== 'customer' && ( // Hide dashboard link for customers
                <div>
                  <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('dashboard')}>
                  <i className="sidepicons fa-solid fa-chess-board mt-3"></i>
                  <span className='mt-3' style={{ marginLeft: '20px' }}>Dashboard</span>
                  </h6>
                    <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('users')}>
                  <i className="sidepicons fa-solid fa-users mt-2"></i>
                  <span className='mt-2' style={{ marginLeft: '10px' }}>Users</span>
                </h6>
                <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('customers')}>
                  <i className="sidepicons fa-solid fa-person mt-2"></i>
                  <span className='mt-2' style={{ marginLeft: '25px' }}>Customers</span>
                </h6>
                <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('tasks')}>
                  <i className="sidepicons fa-solid fa-list-check mt-2"></i>
                  <span className='mt-2' style={{ marginLeft: '18px' }}>Tasks</span>
                </h6>
                <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('notes')}>
                  <i className="sidepicons fa-solid fa-note-sticky mt-2"></i>
                  <span className='mt-2' style={{ marginLeft: '20px' }}>Notes</span>
                </h6>
                </div>
              )}
            
            </>
            ) : (
              <>
                <div className='text-center'>
                <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('profile')}>
                  <i className="sidepicons fa-solid fa-address-card mt-3"></i>
                </h6>
                <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('orders')}>
                  <i className="sidepicons fa-solid fa-layer-group mt-3"></i>
                </h6>

                {designation !== 'customer' && (
                  <div>
                    <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('dashboard')}>
                      <i className="sidepicons fa-solid fa-chess-board mt-3"></i>
                    </h6>
                    <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('users')}>
                      <i className="sidepicons fa-solid fa-users mt-2"></i>
                    </h6>
                    <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('customers')}>
                      <i className="sidepicons fa-solid fa-person mt-2"></i>
                    </h6>
                    <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('tasks')}>
                      <i className="sidepicons fa-solid fa-list-check mt-2"></i>
                    </h6>
                    <h6 className='' style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleMenuItemClick('notes')}>
                      <i className="sidepicons fa-solid fa-note-sticky mt-2"></i>
                    </h6>
                  </div>
                )}
                
                </div>
              </>
            )}
          </div>
          {/* Button to toggle side panel */}
          <div className="toggle-btn" onClick={toggleSidePanel}>
            {isOpen ? <i className="fa-solid fa-arrow-left fa-xl"></i> : <i className="fa-solid fa-arrow-right fa-xl"></i>}
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePanel;
