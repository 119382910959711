import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Loginpage from '../pages/Loginpage';
import Auth from '../hooks/Auth'; // Assuming Auth component remains in `hooks`
import Homepage from '../pages/homepage/Homepage';
import Invoices from '../pages/dashboard/Invoices';
import Labels from '../pages/dashboard/Labels';
import Container from '../pages/dashboard/Container';
import Expenses from '../pages/dashboard/Expenses';
import Receivables from '../pages/dashboard/Receivables';
import Reports from '../pages/dashboard/Reports';
import Bank from '../pages/dashboard/Bank';
import Mpesa from '../pages/dashboard/Mpesa';
import Mastersheet from '../pages/dashboard/Mastersheet';
import Sales from '../pages/dashboard/Sales';
import Notfound from '../pages/Notfoundpage';
import Approvesale from '../pages/dashboard/Approvesale';
import CashPayment from '../pages/dashboard/CashPayment';
import ContainerDetail from '../components/ContainerDetail';
import LabelDetails from '../components/LabelDetails';
import InvoiceDetails from '../components/InvoiceDetails';
import Seller from '../pages/dashboard/Seller';
import SaleDetails from '../components/SaleDetails';
import SellerDetails from '../components/SellerDetails';
import Solditems from '../pages/dashboard/Solditems';
import SolditemsDetails from '../components/SolditemsDetails';
import Commisions from '../pages/dashboard/Commisions';
import Payments from '../pages/dashboard/Payments';
import Salesreport from '../pages/dashboard/Salesreport';
import CommissionDetails from '../components/CommisionDetails';
import Wholesale from '../pages/dashboard/Wholesale';
import Analytics from '../pages/dashboard/Analytics';
import CustomerOrders from '../pages/dashboard/CustomerOrders';
import OrderDetails from '../components/OrderDetails';

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Loginpage />} />
      <Route exact path="/homepage" element={<Auth><Homepage /></Auth>} />
      <Route exact path="/labels" element={<Auth><Labels /></Auth>} />
      <Route exact path="/invoices" element={<Auth><Invoices /></Auth>} />
      <Route exact path="/container" element={<Auth><Container /></Auth>} />
      <Route exact path="/expenses" element={<Auth><Expenses /></Auth>} />
      <Route exact path="/receivables" element={<Auth><Receivables /></Auth>} />
      <Route exact path="/reports" element={<Auth><Reports /></Auth>} />
      <Route exact path="/bank" element={<Auth><Bank /></Auth>} />
      <Route exact path="/mpesa" element={<Auth><Mpesa /></Auth>} />
      <Route exact path="/containerdetails/:id" element={<Auth><ContainerDetail /></Auth>} />
      <Route exact path="/invoicedetails/:id" element={<Auth><InvoiceDetails /></Auth>} />
      <Route exact path="/solditemsdetails/:id" element={<Auth><SolditemsDetails /></Auth>} />
      <Route exact path="/saledetails/:id" element={<Auth><SaleDetails /></Auth>} />
      <Route exact path="/orderdetails/:id" element={<Auth><OrderDetails /></Auth>} />
      <Route exact path="/labeldetails/:id" element={<Auth><LabelDetails /></Auth>} />
      <Route exact path="/sellerdetails/:id" element={<Auth><SellerDetails /></Auth>} />
      <Route exact path="/cashpayment" element={<Auth><CashPayment /></Auth>} />
      <Route exact path="/solditems" element={<Auth><Solditems /></Auth>} />
      <Route exact path="/sellers" element={<Auth><Seller /></Auth>} />
      <Route exact path="/approvesale" element={<Auth><Approvesale /></Auth>} />
      <Route exact path="/mastersheet" element={<Auth><Mastersheet /></Auth>} />
      <Route exact path="/salesreport" element={<Auth><Salesreport /></Auth>} />
      <Route exact path="/payments" element={<Auth><Payments /></Auth>} />
      <Route exact path="/analytics" element={<Auth><Analytics /></Auth>} />
      <Route exact path="/commisions" element={<Auth><Commisions /></Auth>} />
      <Route exact path="/commisiondetails/:id" element={<Auth><CommissionDetails /></Auth>} />
      <Route exact path="/your-orders/:id" element={<Auth><CustomerOrders /></Auth>} />
      <Route exact path="/sales" element={<Auth><Sales /></Auth>} />
      <Route exact path="/wholesale" element={<Auth><Wholesale /></Auth>} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
};

export default AppRoutes;
