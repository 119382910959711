import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../contexts/UserContext.js';
import { login } from '../api/api.js';

const Loginpage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const cookies = useMemo(() => new Cookies(), []);

  const { setUser } = useUser(); // Import setUser from UserContext

  useEffect(() => {
    const jwtToken = cookies.get('jwt');

    if (jwtToken) {
      try {
        const decoded = jwtDecode(jwtToken);

        if (decoded.exp * 1000 > Date.now()) {
          navigate('/homepage', {
            state: { username: decoded.username, email: decoded.email },
          });
        } else {
          cookies.remove('jwt');
        }
      } catch (error) {
        console.error('Error decoding JWT:', error);
      }
    }
  }, [cookies, navigate]);

  const submitloginform = async (e) => {
    try {
      e.preventDefault();
      const userdetails = { email, password };
      const response = await login(userdetails);
      if (response.status === 200) {
        cookies.set('jwt', response.data.token, { path: '/' });
        cookies.set('designation', response.data.designation, { path: '/' }); 
        
        const token = response.data.token;
        const decoded = jwtDecode(token);

        const userid = decoded.userId;
        localStorage.setItem('userid', userid);
        
        // Store user data in local storage
        localStorage.setItem('token', token);
        localStorage.setItem('designation', decoded.designation);
        localStorage.setItem('username', decoded.username);
        localStorage.setItem('email', decoded.email);
        localStorage.setItem('department', decoded.department);
        localStorage.setItem('phone', decoded.phone);
        localStorage.setItem('credit_limit', decoded.credit_limit);
        localStorage.setItem('name', decoded.name);

        console.log("Decoded token:", decoded);

        setUser({ username: decoded.username, email: decoded.email, designation: decoded.designation });
        
        toast.success('Login successful!');
        navigate('/homepage');
      } else {
        toast.error('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error on login page:', error);
      toast.error('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card p-4">
        <h3 className="text-center">SV Trading LTD</h3>
        {/* <h4 className="text-center">Login</h4> */}
        <form onSubmit={submitloginform}>
          <div className="mb-3">
            <label className="form-label">Email:</label>
            <input
              className="form-control"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password:</label>
            <input
              className="form-control"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className="btn btn-success w-100" type="submit">Login</button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Loginpage;
